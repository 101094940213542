<template>
  <footer>
    <div class="inner-layout d-flex justify-space-between">
      <div>
        <div class="footer-logo"><img src="@/assets/img/common/footer_logo.png" alt="꼬랩"></div>
        <p>
          {{ info.siteName }} ㅣ 대표이사 {{ info.ceoName }}  ㅣ  {{ info.addr }}<br>
          사업자번호: {{ info.bizNum && info.bizNum.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3') }}  ㅣ  통신판매업신고: {{ info.comSaleNum }}<br>
          Tel : {{ info.comTel }}  ㅣ  E-mail : {{ info.comEmail }}
        </p>
        <p class="copy">v1.6.2©2021. dreaminsight. All Rights Reserved.</p>
      </div>
      <div class="ta-right">
        <div class="link-menu">
          <router-link to="/policy/2">개인정보처리방침</router-link>
          <router-link to="/policy/1">이용약관</router-link>
          <router-link to="/notice">고객센터</router-link>
        </div>
        <p v-html="info.bizHour && info.bizHour.nl2br()"></p>
      </div>
    </div>
    <div class="quick_top_btn" @click="goTop"><span></span></div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      info: {
        siteName: '',
        comName: '',
        bizNum: '',
        comSalemNum: '',
        ceoName: '',
        comTel: '',
        comEmail: '',
        addr: '',
        bizHour: '',
      },
    }
  },
  created() {
    this.getCompanyInfo()
  },
  methods:{
    async getCompanyInfo() {
      const { result, data } = await this.$api.getCompanyInfo()
      if (result === 'success') this.info = data
    },
    goTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
}
</script>
